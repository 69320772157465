/* eslint-disable react/no-children-prop */
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from '../screens/Home'
import AppRedefinirSenha from '../screens/AppRedefinirSenha'
import BomDiaPr from '../screens/MensagensJornalismos/BomDiaPr'
import BoaNoitePr from '../screens/MensagensJornalismos/BoaNoitePr'
import MeioDiaPr from '../screens/MensagensJornalismos/MeioDiaPr'
import Generico from '../screens/MensagensJornalismos/Generico'
import Natal from '../screens/MensagensJornalismos/Natal'
import DiaDosNamorados from '../screens/MensagensJornalismos/DiaDosNamorados'

import AmorDeMae from '../screens/Novelas/AmorDeMae'
import FamiliaETudo from '../screens/Novelas/FamiliaETudo/FamiliaETudo'

import Televisando from '../screens/Programas/Televisando'
import TelevisandoFamilia from '../screens/Programas/Televisando/Familia'
import TelevisandoProfessor from '../screens/Programas/Televisando/Professor'

import ConcursoCaminhosDoCampo from '../screens/Programas/CaminhosDoCampo'
import Natal2021 from '../screens/Programas/Natal2021'

import Video from '../screens/Videos/Video'

import AtendimentoAvaliacao from '../screens/Atendimento/Avaliacao'

import ReciclaIndicar from '../screens/Recicla/Indicar'

import PesquisaBairro from '../screens/Pesquisa/Bairro'

import Amigo from '../screens/Programas/DiaDoAmigo2021'
import BemVerao2021 from '../screens/Programas/BemVerao2021'
import RPCGame2021 from '../screens/Programas/RPCGame2021'
import EncontroAnimalFoz2021 from '../screens/Programas/EncontroAnimalFoz2021'
import VivaPedreira2021 from '../screens/Programas/VivaPedreira2021'
import PlateiaEstudioC from '../screens/Programas/PlateiaEstudioC'

import FantasmaNightRun3 from '../screens/Programas/Cupons/FantasmaNightRun3'
import CorridaCaminhadaDiaInternacionalMulheres2022 from '../screens/Programas/Cupons/CorridaCaminhadaDiaInternacionalMulher'
import TributoQueen from '../screens/Programas/Cupons/TributoQueen'
import DireStraitsLegacy from '../screens/Programas/Cupons/DireStraitsLegacy'
import MarchaJesus2022 from '../screens/Programas/MarchaJesus2022'
import CuritibaCountryFestival2022 from '../screens/Programas/Cupons/CuritibaCountryFestival2022'
import CuritibaCountryFestivalSorteio2022 from '../screens/Programas/CuritibaCountryFestivalSorteio2022'
import ForcaDoAmor2022 from '../screens/Programas/Cupons/ForcaDoAmor2022'
import ForcaDoAmorSorteio2022 from '../screens/Programas/Cupons/ForcaDoAmorSorteio2022'
import ConcursoCaminhosDoCampo2022 from '../screens/Programas/CaminhosDoCampo2022'
import ConcursoCaminhosDoCampo2023 from '../screens/Programas/CaminhosDoCampo2023'
import ConcursoCaminhosDoCampo2024 from '../screens/Programas/CaminhosDoCampo2024'
import MeiaMaratonaClubeAlice2022 from '../screens/Programas/Cupons/MeiaMaratonaClubeAlice2022'
import Cabare2022 from '../screens/Programas/Cupons/Cabare2022'
import CabareSorteio2022 from '../screens/Programas/Cupons/CabareSorteio2022'
import ExpoFlor2022 from '../screens/Programas/Cupons/ExpoFlor2022'
import MarisaMonte2022 from '../screens/Programas/Cupons/MarisaMonte2022'
import DinhoOuroPreto2022 from '../screens/Programas/Cupons/DinhoOuroPreto2022'
import TheWailers2022 from '../screens/Programas/Cupons/TheWailers2022'
import CorinneBailey2022 from '../screens/Programas/Cupons/CorinneBailey2022'
import SambaSertaoVerao2022 from '../screens/Programas/Cupons/SambaSertaoBrasa2022'
import FestivalMiaCara2022 from '../screens/Programas/Cupons/FestivalMiaCara2022'
import GunsNRoses2022 from '../screens/Programas/Cupons/GunsNRoses2022'
import CountryFestival2022 from '../screens/Programas/Cupons/CountryFestival2022'
import BaileNegoVeio2022 from '../screens/Programas/Cupons/BaileNegoVeio2022'
import SambaCuritibaSorteio2022 from '../screens/Programas/SambaCuritibaSorteio2022'
import AppHandler from '../screens/AppHandler'
import AppStoreUpdate from '../screens/AppStoreUpdate'
import ProgramaVisitas from '../screens/Programas/ProgramaVisitas'
import Evento from '../screens/Evento'
import AtendimentoAvaliacaoSalesForce from '../screens/AtendimentoSalesForce/Avaliacao'
import GloboEsporte2024 from '../screens/Programas/GloboEsporte2024'
import PlanetarioMaringa2024 from '../screens/Programas/Cupons/PlanetarioMaringa2024/PlanetarioMaringa2024'
import AgasalhoCinema2024 from '../screens/Programas/Cupons/AgasalhoCinema2024/AgasalhoCinema2024'
import AniversarioTeatroGuaira from '../screens/AniversarioTeatroGuaira/playlist'
import VideoAniversarioTeatroGuaira from '../screens/AniversarioTeatroGuaira/video'
import EventoCupom from '../screens/Evento/Cupom'

const RouterProvider = () => (
  <Router>
    <Switch>
      <Route
        path="/mensagens-jornalismos/mensagens-de-natal"
        component={Natal}
      />
      <Route path="/mensagens-jornalismos/bomdiapr" component={BomDiaPr} />
      <Route path="/mensagens-jornalismos/boanoitepr" component={BoaNoitePr} />
      <Route path="/mensagens-jornalismos/meiodiapr" component={MeioDiaPr} />
      <Route path="/mensagens-jornalismos" component={Generico} />
      <Route path="/dia-dos-namorados" component={DiaDosNamorados} />
      <Route path="/amor-de-mae" component={AmorDeMae} />
      <Route path="/familia-e-tudo" component={FamiliaETudo} />
      <Route path="/videos" component={Video} />
      <Route
        path="/avaliacao-atendimento/:salesForceCaseId/:idPesquisa"
        component={AtendimentoAvaliacaoSalesForce}
      />
      <Route
        path="/atendimento/:id/avaliacao"
        component={AtendimentoAvaliacao}
      />
      <Route path="/recicla/indicar" component={ReciclaIndicar} />
      <Route exact path="/televisando/familia" component={TelevisandoFamilia} />
      <Route
        exact
        path="/televisando/professor"
        component={TelevisandoProfessor}
      />
      <Route exact path="/televisando" component={Televisando} />
      <Route exact path="/pesquisa/bairro" component={PesquisaBairro} />
      <Route
        exact
        path="/caminhos-do-campo"
        component={ConcursoCaminhosDoCampo}
      />
      <Route exact path="/globo-esporte-2024" component={GloboEsporte2024} />
      <Route exact path="/natal-2021" component={Natal2021} />
      <Route exact path="/dia-do-amigo-2021" component={Amigo} />
      <Route exact path="/bem-verao-2021" component={BemVerao2021} />
      <Route exact path="/rpc-game-2021" component={RPCGame2021} />
      <Route
        exact
        path="/planetario-maringa-2024"
        component={PlanetarioMaringa2024}
      />
      <Route
        exact
        path="/agasalho-cinema-2024"
        component={AgasalhoCinema2024}
      />
      <Route
        exact
        path="/encontro-animal-foz-2021"
        component={EncontroAnimalFoz2021}
      />
      <Route exact path="/viva-pedreira-2021" component={VivaPedreira2021} />
      <Route exact path="/fantasma-night-run-3" component={FantasmaNightRun3} />
      <Route
        exact
        path="/corrida-caminhada-dia-internacional-mulheres-2022"
        component={CorridaCaminhadaDiaInternacionalMulheres2022}
      />
      <Route exact path="/plateia-estudio-c" component={PlateiaEstudioC} />
      <Route exact path="/tributo-queen" component={TributoQueen} />
      <Route exact path="/dire-straits-legacy" component={DireStraitsLegacy} />
      <Route exact path="/marcha-jesus-2022" component={MarchaJesus2022} />
      <Route
        exact
        path="/curitiba-country-festival-2022"
        component={CuritibaCountryFestival2022}
      />
      <Route
        exact
        path="/curitiba-country-festival-sorteio-2022"
        component={CuritibaCountryFestivalSorteio2022}
      />
      <Route exact path="/forca-do-amor-2022" component={ForcaDoAmor2022} />
      <Route
        exact
        path="/forca-do-amor-sorteio-2022"
        component={ForcaDoAmorSorteio2022}
      />
      <Route
        exact
        path="/caminhos-do-campo-2022"
        component={ConcursoCaminhosDoCampo2022}
      />
      <Route
        exact
        path="/caminhos-do-campo-2023"
        component={ConcursoCaminhosDoCampo2023}
      />
      <Route
        exact
        path="/caminhos-do-campo-2024"
        component={ConcursoCaminhosDoCampo2024}
      />
      <Route
        exact
        path="/meia-maratona-clube-alice-2022"
        component={MeiaMaratonaClubeAlice2022}
      />
      <Route exact path="/cabare-2022" component={Cabare2022} />
      <Route exact path="/cabare-sorteio-2022" component={CabareSorteio2022} />
      <Route exact path="/expo-flor-2022" component={ExpoFlor2022} />
      <Route exact path="/marisa-monte-2022" component={MarisaMonte2022} />
      <Route
        exact
        path="/dinho-ouro-preto-2022"
        component={DinhoOuroPreto2022}
      />
      <Route exact path="/the-wailers-2022" component={TheWailers2022} />
      <Route exact path="/corinne-bailey-2022" component={CorinneBailey2022} />
      <Route
        exact
        path="/samba-sertao-brasa-2022"
        component={SambaSertaoVerao2022}
      />
      <Route
        exact
        path="/festival-mia-cara-2022"
        component={FestivalMiaCara2022}
      />
      <Route exact path="/guns-n-roses-2022" component={GunsNRoses2022} />
      <Route
        exact
        path="/curitiba-country-festival-2-2022"
        component={CountryFestival2022}
      />
      <Route
        exact
        path="/baile-nego-veio-2-2022"
        component={BaileNegoVeio2022}
      />
      <Route
        exact
        path="/samba-curitiba-sorteio-2022"
        component={SambaCuritibaSorteio2022}
      />
      <Route
        path="/aniversario-teatro-guaira"
        component={AniversarioTeatroGuaira}
      />
      <Route
        path="/video-aniversario-teatro-guaira/"
        component={VideoAniversarioTeatroGuaira}
      />
      <Route exact path="/programa-visitas" component={ProgramaVisitas} />
      <Route path="/evento/:id" children={<Evento />} />
      <Route path="/evento-cupom/:id" children={<EventoCupom />} />
      <Route path="/install" component={AppHandler} />
      <Route path="/app" component={AppHandler} />
      <Route path="/update" component={AppStoreUpdate} />
      <Route exact path="/redefinir-senha" component={AppRedefinirSenha} />
      <Route path="/" component={Home} />
    </Switch>
  </Router>
)

export default RouterProvider
