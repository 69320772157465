import React, { useContext, useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Player, Controls } from '@lottiefiles/react-lottie-player'

import Loading from '../../components/Loading'

import { ATENDIMENTOS_API_URL } from '../../constants/api'
import {
  FirebaseContext,
  CurrentUserContext,
  ParamsContext
} from '../../Contexts'
import { useCallbackStatic } from '../../hooks/utils'

import logoRpc from '../../assets/images/logo.png'
import { ReactComponent as FacebookSVG } from '../../assets/images/facebook.svg'
import { ReactComponent as InstagramSVG } from '../../assets/images/instagram.svg'
import { ReactComponent as LinkedinSVG } from '../../assets/images/linkedin.svg'

const Base = (props) => {
  const {
    title,
    description,
    imagePath,
    programName,
    satisfacao,
    comentarios,
    nome,
    telefone,
    email,
    clearForm
  } = props

  const { salesForceCaseId, idPesquisa } = useParams()

  const { firebaseAuth } = useContext(FirebaseContext)
  const [loading, setLoading] = useState(true)
  const [result, setResult] = useState(false)
  const [hasError, setHasError] = useState(undefined)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (salesForceCaseId === 'null' || idPesquisa === 'null') {
      setHasError(
        'Não foi possível responder a pesquisa, tente novamente mais tarde'
      )
    }
  }, [salesForceCaseId, idPesquisa])

  useEffect(() => {
    const isValidCustomerCurvey = async () => {
      try {
        let token = ''

        if (firebaseAuth.currentUser) {
          token = await firebaseAuth.currentUser.getIdToken()
        }

        let result

        if (token) {
          const url = `${ATENDIMENTOS_API_URL}/avaliacao/${salesForceCaseId}`
          result = await axios.get(url, {
            headers: { Authorization: `Bearer ${token}` }
          })
        } else {
          const url = `${ATENDIMENTOS_API_URL}/salesforce/avaliacao/${salesForceCaseId}`
          result = await axios.get(url)
        }

        const response = result.data

        if (response.valid) {
          setLoading(false)
        } else {
          setHasError('A avaliação já foi respondida')
        }
      } catch (error) {
        console.log('isValidCustomerCurvey:error ', error)
      }
    }
    isValidCustomerCurvey()
  }, [firebaseAuth.currentUser])

  const back = useCallbackStatic(() => {
    setResult(false)
  })

  const send = useCallbackStatic(async (data) => {
    setLoading(true)

    const body = {
      salesForceCaseId,
      idPesquisa,
      satisfacao: data.satisfacao,
      comentarios: data.comentarios
    }

    const url = `${ATENDIMENTOS_API_URL}/salesforce/avaliacao`
    await axios.put(url, body)

    clearForm()
    setResult(true)
    setLoading(false)
  })

  const sendMessage = useCallback(() => {
    if (!salesForceCaseId) {
      alert('Atendimento inválido')
      return
    }

    if (!satisfacao || satisfacao.length <= 0) {
      setError(true)
      toast.error('Escolha seu nível de satisfação', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored'
      })
      return
    }

    send({
      salesForceCaseId,
      satisfacao,
      comentarios,
      nome,
      telefone,
      email
    })
  }, [
    salesForceCaseId,
    idPesquisa,
    satisfacao,
    comentarios,
    nome,
    telefone,
    email
  ])

  const renderError = useCallback(
    () => (
      <div className="container">
        <div className="container-avaliacao-salesforce">
          <div className="title title-error">
            <h1>{hasError}</h1>
          </div>
          <div className="logo">
            <Player
              autoplay
              keepLastFrame
              src={
                hasError === 'Usuário não autenticado'
                  ? 'https://lottie.host/9b0cc4f5-937f-4dcc-bd4c-0450d0fa6792/l1erLfIKCb.json'
                  : hasError ===
                    'Não foi possível responder a pesquisa, tente novamente mais tarde'
                  ? 'https://lottie.host/b39caeac-5ec3-4e17-b07d-680a004261ed/odTzXqETNP.json'
                  : 'https://lottie.host/0fdf6647-4740-456b-ab84-2c8021f254a8/oBADE68O2H.json'
              }
              style={{ height: '300px', width: '300px' }}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
          </div>
          <div className="subtitle">
            <h6>Fique por dentro das novidades da RPC:</h6>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/rpcparana/?locale=pt_BR"
              target="_blank"
            >
              <FacebookSVG />
            </a>
            <a href="https://www.instagram.com/rpcparana/" target="_blank">
              <InstagramSVG />
            </a>
            <a
              href="https://br.linkedin.com/company/rede-paranaense-de-comunica-o"
              target="_blank"
            >
              <LinkedinSVG />
            </a>
          </div>
        </div>
      </div>
    ),
    [hasError, title]
  )

  const renderContent = useCallback(() => {
    if (!result) {
      return (
        <div className="container-avaliacao-salesforce">
          <div className="logo">
            <img src={logoRpc} alt="Logo RPC" />
          </div>

          <div className="titenquete">
            <h2>{title}</h2>
          </div>

          <div className="descenquete">{description(error)}</div>
          <div className="container">
            <div className="btsalvar">
              <button onClick={sendMessage}>Enviar</button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="container">
        <div className="container-avaliacao-salesforce">
          <div className="title">
            <h1>Agradecemos pela sua participação</h1>
          </div>
          <div className="subtitle">
            <h6>Sua resposta foi registrada</h6>
          </div>
          <div className="logo">
            <Player
              autoplay
              keepLastFrame
              src="https://lottie.host/9b0cc4f5-937f-4dcc-bd4c-0450d0fa6792/l1erLfIKCb.json"
              style={{ height: '200px', width: '200px' }}
            >
              <Controls
                visible={false}
                buttons={['play', 'repeat', 'frame', 'debug']}
              />
            </Player>
          </div>
          <div className="subtitle">
            <h6>Fique por dentro das novidades da RPC:</h6>
          </div>
          <div className="social-media">
            <a
              href="https://www.facebook.com/rpcparana/?locale=pt_BR"
              target="_blank"
            >
              <FacebookSVG />
            </a>
            <a href="https://www.instagram.com/rpcparana/" target="_blank">
              <InstagramSVG />
            </a>
            <a
              href="https://br.linkedin.com/company/rede-paranaense-de-comunica-o"
              target="_blank"
            >
              <LinkedinSVG />
            </a>
          </div>
        </div>
      </div>
    )
  }, [back, description, result, sendMessage, title, error])

  return (
    <React.Fragment>
      <div className="enqueterpc">
        {hasError ? renderError() : loading ? <Loading /> : renderContent()}
      </div>
    </React.Fragment>
  )
}

export default Base
