import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import axios from 'axios'

import { CurrentUserContext, FirebaseContext } from '../../Contexts'

import sendUserConsent from '../../utils/sendUserConsent'

import Modal from '../../components/Modal'
import ToggleSwitch from '../../components/ToggleSwitch'

import getClientIPv4 from '../../utils/getPublicIPv4'
import getDeviceInfos from '../../utils/getDeviceInfos'
import getTicket from '../../utils/getTicket'

import { toTitleCase } from '../../utils/helpers'
import removeDiacritics from '../../utils/formatters/removeDiacritics';
import convertFirebaseTimestampToDate from '../../utils/convertFirebaseTimestampToDate'

import { CONTEUDO_API_URL } from '../../constants/api'

const HeaderComponent = ({ bannerUrl, children }) => (
  <React.Fragment>
    <div className="enqueterpc">
      <div className="imagemdestaque">
        <img src={bannerUrl} alt="banner" />
      </div>
      {children}
    </div>
   </React.Fragment>
)

const Cupom = () => {
  const { id } = useParams()

  const { firebaseFirestore: db, firebaseAuth } = useContext(FirebaseContext)
  const { currentUser } = useContext(CurrentUserContext)

  const [eventData, setEventData] = useState({})
  const [currentEventListTicket, setCurrentEventListTicket] = useState({})
  const [userEventData, setUserEventData] = useState({})

  const [termoAceite, setTermoAceite] = useState(false)
  const [mostrarTermoModal, setMostrarTermoModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [finish, setFinish] = useState(false)
  const [error, setError] = useState('')

  const [userTicket, setUserTicket] = useState(undefined)
  const [isUserTermExpired, setIsUserTermExpired] = useState(false)

  const [buttonText, setButtonText] = useState('Carregando...')

  useEffect(() => {
    const getEventData = async () => {
      const docRef = await db
        .collection('sistema-reserva-tickets')
        .doc(id)

      if(docRef.empty) {
        console.log('Evento não encontrado')
        setError('Evento não encontrado')
        return
      }

      const data = await (await docRef.get()).data()

      if(data.status !== 'PUBLICADO') {
        setError('Evento não disponível')
        return
      }

      setEventData(data)
    }

    const getCurrentEventListTicket = async () => {
      const docRef = db
        .collection('sistema-reserva-tickets')
        .doc(id)
        .collection('tickets')

      if(docRef.empty) {
        console.log('Sem tickets')
        return
      }

      const listTickets = await docRef
        .where('enabled', '==', true)
        .orderBy('createdAt', 'asc')
        .get()

      if (listTickets.empty) {
        console.log('Sem tickets disponíveis')
        setButtonText('Esgotado')
        return
      }

      let esgotados = true

      for (const doc of listTickets.docs) {
        const data = doc.data()
        const { consumidos, quantidade } = data

        if(consumidos < quantidade) {
          setCurrentEventListTicket(data)
          setButtonText('Pegar Cupom')

          esgotados = false
          break
        }
      }

      if(esgotados) {
        console.log('Todos os tickets estão esgotados')
        setButtonText('Esgotado')
      }
    }

    if(id) {
      getEventData()
      getCurrentEventListTicket()
    }
  }, [id])

  useEffect(() => {
     const getUserEventData = async () => {
      console.log('Buscando dados do usuário')

      const listaTickets = (await db
        .collection('sistema-reserva-tickets')
        .doc(id)
        .collection('tickets')
        .get())
      .docs

      let data

      for await (const tickets of listaTickets) {
        const collection = tickets.ref.collection('cupons')

        if (collection.empty) {
          console.log('Lista de cupons vazia')
          continue
        }

        const userDocInTickets = await collection.where('id', '==', currentUser.uid).get()

        if(userDocInTickets.empty) {
          console.log('Usuário não possui cupom')
          continue
        }

        data = await userDocInTickets.docs[0].data()

        setUserEventData(data)
        setUserTicket(data.ticket)
        break
      }

      if(data) {
        setFinish(true)
      }
      else {
        setUserEventData(undefined)
      }
    }

    const checkTermIsExpired = async () => {
      const { terms_of_use_events } = currentUser

      if(!terms_of_use_events) {
        setIsUserTermExpired(true)
        return
      }

      const { _seconds, _nanoseconds } = terms_of_use_events.updatedAt
      const updatedAt = convertFirebaseTimestampToDate(_seconds, _nanoseconds)

      if(moment().diff(updatedAt, 'months') >= 6) {
        setIsUserTermExpired(true)
      } else {
        setTermoAceite(true)
      }
    }

    if(currentUser && (
        typeof userEventData !== 'undefined'
        && Object.keys(userEventData).length === 0
        && Object.keys(currentEventListTicket).length > 0
      )
    ) {
      getUserEventData()
      checkTermIsExpired()
    }
  }, [currentEventListTicket, userEventData, currentUser])

  useEffect(() => {
    let ticket
    let docId

    const startRegister = async () => {
      docId = `${currentUser.uid}-${moment().unix()}`

      const result = await saveData()

      if(!result) {
        console.error('Erro ao computar ticket')
        return
      }

      await saveUserTerm()
      await saveInSalesforce()
    }

    const saveData = async () => {
      try {
        const docRef = await db
            .collection('sistema-reserva-tickets')
            .doc(id)
            .collection('tickets')
            .doc(`${currentEventListTicket.idEventoTicket}`)

        await db.runTransaction(async transaction => {
          const doc = await transaction.get(docRef);
          const docData = doc.data();

          const { exibirCupom } = eventData
          const { codigo, consumidos, quantidade, enabled } = docData

          const now = new Date()

          if(!enabled) {
            console.warn('TRANSACTION: Tickets desabilitados')
            throw new Error('Erro: tickets esgotados')
          }

          if(consumidos >= quantidade) {
            console.warn('TRANSACTION: Tickets esgotados')
            throw new Error('Erro: tickets esgotados')
          }

          const newTicketIndex = consumidos + 1

          ticket = exibirCupom ? getTicket(codigo, newTicketIndex) : Math.floor(now.getTime() / 1000)

          await transaction.update(docRef, { consumidos: newTicketIndex })

          setUserTicket(ticket)

          const ticketDocRef = docRef.collection('cupons').doc(currentUser.uid)
          await transaction.set(ticketDocRef, {
            ticket,
            id: currentUser.uid,
            nome: currentUser.nome,
            email: currentUser.email,
            created_at: now,
          })
        })

        return true
      } catch(e) {
        if(e.message) {
          setError(e.message)
        } else {
          console.error(`TRANSACTION: ${e}`)
          setError('Erro ao computar ticket')
        }
        return false
      }
    }

    const saveUserTerm = async () => {
      try {
        const { titulo, hashPrivacy } = eventData

        const collection = await db
          .collection('flerken-interatividade')
          .doc('TERMOS_ACEITE')
          .collection('termos')

        const termo = `termo${toTitleCase(removeDiacritics(titulo))}`

        await collection.doc(docId).set({
          termo,
          id: docId,
          created_at: moment().toDate(),
          usr_email: currentUser.email,
          usr_id: currentUser.uid,
          usr_name: currentUser.nome,
          cpf: currentUser.cpf,
          ipV4: await getClientIPv4(),
          device_infos: getDeviceInfos()
        })

        try {
          await sendUserConsent({
            name: currentUser.nome,
            email: currentUser.email,
            origin: hashPrivacy
          })
        } catch(e) {
          console.warn('Usuário já aceitou os termos')
        }

        if(isUserTermExpired) {
          await db.collection('flerken-users').doc(currentUser.uid).update({
            terms_of_use_events: {
              updatedAt: new Date(),
              aceite: true
            }
          })
        }
      } catch (e) {
        console.error(e)
        setError('Falha ao salvar termo de aceite.')
      }
    }

    const saveInSalesforce = async () => {
      const { idSalesforceTelespectador } = currentUser
      const { idEventoTicket } = currentEventListTicket

      if(!idSalesforceTelespectador || !idEventoTicket || !ticket) {
        setError('Houve um erro ao resgatar seu cupom. Tente novamente mais tarde.')

        if(!idSalesforceTelespectador) {
          console.error('Usuário sem idSalesforceTelespectador')
        }

        if(!idEventoTicket) {
          console.error('Lista de tickets sem idEventoTicket')
        }

        if(!ticket) {
          console.error('Ticket não gerado')
        }

        return
      }


      try {
        const url = `${CONTEUDO_API_URL}/evento/usuario-app/${idEventoTicket}`
        const token = await firebaseAuth.currentUser.getIdToken()
        const headers = { 'Authorization': `Bearer ${token}` }
        const body = { codigo_ticket: ticket }

        await axios.post(url, body, { headers })
      } catch (e) {
        console.error(`Erro ao salvar ticket na Salesforce: ${e}`)
         try {
          await db.collection('sistema-reserva-tickets-erros')
            .doc(`${currentUser.uid.slice(0, 14)}-${idEventoTicket}`)
            .set({
              idEventoTicket,
              codigoTicket: ticket,
              uid: currentUser.uid,
              processado: false,
              createdAt: moment().toDate()
          })
        } catch(ee) {
          console.error('Erro geral:', ee)

          const ticketDocRef = db.collection('sistema-reserva-tickets')
            .doc(id)
            .collection('tickets')
            .doc(`${currentEventListTicket.idEventoTicket}`)

          await db.runTransaction(async transaction => {
            const doc = await transaction.get(ticketDocRef);
            const docData = doc.data();

            const { consumidos } = docData

            await transaction.update(ticketDocRef, { consumidos: consumidos - 1 })

            console.log('Ticket descotado')
          })

          await ticketDocRef.collection('cupons').doc(currentUser.uid).delete()

          console.log('Cupom removido')

          await db
            .collection('flerken-interatividade')
            .doc('TERMOS_ACEITE')
            .collection('termos')
            .doc(docId)
            .delete()

          console.log('Termo de aceite deletado')

          setError(`Tente novamente mais tarde. Se o erro persistir, entre em contato com o suporte informando o código: ${currentUser.uid}`)
          return
        }
      }

      setFinish(true)
      setLoading(false)
    }

    if(loading && currentUser) {
      startRegister()
    }
  }, [loading])

  useEffect(() => {
    if(error.length > 0) {
      setLoading(false)
      setFinish(false)
    }
  }, [error])

  const HeaderComponentRendered = useCallback(({ children }) => (
    <HeaderComponent bannerUrl={eventData.banner || ''}>
      {children}
    </HeaderComponent>
  ), [eventData.banner])

  const disabledButton = () => {
    const { consumidos, quantidade } = currentEventListTicket
    return !(
      termoAceite
      && typeof userEventData === 'undefined'
      && consumidos < quantidade
    )
  };

  if(!currentUser) {
    return <HeaderComponentRendered>
      <div className="container">
        <div className="titenquete">
            <p className="title">Usuário não identificado</p>
        </div>
        <div className="titenquete">
            <p style={{ fontWeight: 400 }}>É necessário se autenticar no app para efetuar a inscrição.</p>
        </div>
      </div>
    </HeaderComponentRendered>
  }

  if(error.length > 0) {
    const { exibirCupom } = eventData

    return (
      <HeaderComponentRendered>
        <div className="container">
          <div className="titenquete title">
            <p>Ocorreu um erro ao gerar o seu {exibirCupom ? 'código' : 'desconto'}.</p>
            <p>{error}</p>
          </div>
      </div>
      </HeaderComponentRendered>
    )
  }

  if(loading) {
    const { exibirCupom } = eventData

     return (
      <HeaderComponentRendered>
        <div className="container">
          <div className="titenquete title">
            <p>Seu {exibirCupom ? 'código' : 'desconto'} está sendo gerado.</p>
          </div>
          <div className="titenquete content">
            <p>Por favor, não feche esta tela até o processo finalizar.</p>
          </div>
        </div>
        <img src="/imgs/giphy.gif" className="img-loading"/>
      </HeaderComponentRendered>
    )
  }

  if(finish) {
    const {
      exibirCupom,
      cadastroLink,
      textoBotaoCadastroLink,
      descricao,
    } = eventData

    return (
      <HeaderComponentRendered>
         <div className="container">
          <div className="titenquete title cupom">
            {exibirCupom ? 'Código' : 'Desconto'} gerado com sucesso!
          </div>
          {exibirCupom && userTicket && <>
              <div className="titenquete content cupom">
                Seu código é
              </div>
              <div className="titenquete cupom code">
                {userTicket}
              </div>
            </>}
          <div className="titenquete aviso parceiro">
            <div dangerouslySetInnerHTML={{ __html: descricao }} />
          </div>
          {cadastroLink && <>
            <div className="btsalvar" style={{ textAlign: 'center', margin: '20px 0px 10px' }}>
              <button onClick={() => window.open(cadastroLink, '_blank')} className="btsalvar">
                {textoBotaoCadastroLink}
              </button>
            </div>
          </>}
          <p>Eventuais dúvidas, entre em contato através do email <span style={{ display: 'inline-block', textDecoration: 'underline' }}>eventos@rpc.com.br</span> e não esqueça de colocar o nome do evento no assunto.</p>
      </div>
      </HeaderComponentRendered>
    )
  }

  const {
    termo,
    titulo,
    descricaoInicial,
  } = eventData

  return (
   <HeaderComponentRendered>
    <div className="container">
      <div className="descenquete">
        {isUserTermExpired ? (
          <>
           <div className="opcenquete align">
            <ToggleSwitch name="toggle-aceito" checked={termoAceite} style={{ width: '50px' }}
              setChecked={() => setTermoAceite(prev => !prev)}
            />
            <button type="button" className="btTransparente" style={{ width: '100%' }}
              onClick={() => {
                  document.body.scrollTop = 0 // For Safari
                  document.documentElement.scrollTop = 0 // FF, GC, Opera
                  document.body.style.overflowY = 'hidden';
                  setMostrarTermoModal(_ => true)
              }}
            >
              <span>
                  Declaro que li e aceito os <strong>termos de privacidade</strong> do evento <strong>{titulo}</strong>
              ​</span>
            </button>
          </div>
          <div className="texto-atencao" style={{ marginTop: '14px' }}>
          {descricaoInicial && <div dangerouslySetInnerHTML={{ __html: descricaoInicial }} style={{ margin: '15px 0px' }}></div>}
          {!termoAceite && (
            <span>
              <strong>Atenção</strong>: Neste caso (
              <strong>NÃO ACEITE</strong>) não será possível sua participação
              no evento<strong> {titulo}</strong>.
              <br /><br />
              Por favor, em caso de dúvidas, envie uma mensagem para o aplicativo
              “Você na RPC”.
            </span>
          )}
          </div>
        </>
      ) : <p>Não perca essa oportunidade! Garanta seu ingresso com <strong>desconto especial</strong> usando nosso cupom exclusivo.</p>}

        {mostrarTermoModal && (
          <Modal
            setTermoAceito={() => setTermoAceite(prev => !prev)}
            setShowModal={setMostrarTermoModal}
          >
            <div dangerouslySetInnerHTML={{ __html: termo }} />
          </Modal>
        )}
        </div>
      <div className="btsalvar">
          <button onClick={() => setLoading(true)} className="btsalvar" disabled={disabledButton()}>
            {buttonText}
          </button>
      </div>
    </div>
   </HeaderComponentRendered>
  )
}

export default Cupom
